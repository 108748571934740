<template>
  <div>
    <span class="text-muted title mb-4">Cadastre a nova senha</span>
    <div class="login-form" no-body>
      <b-form @submit.prevent="handleSubmitNewPassword()">
        <section v-if="canChangePass">
          <b-form-group :label="$t('auth.password')">
            <b-form-input
              type="password"
              placeholder="****"
              v-model="$v.form.password.$model"
              :class="$v.form.password.$error && 'is-invalid'"
              trim
            >
            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.form.password.required">
              Esse campo é obrigatório
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.form.password.minLength">
              A senha deve conter no mínimo 6 caracteres
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="$t('auth.change-password')">
            <b-form-input
              type="password"
              placeholder="****"
              v-model="$v.form.confirm_password.$model"
              :class="$v.form.confirm_password.$error && 'is-invalid'"
              trim
            ></b-form-input>
            <b-form-invalid-feedback v-if="!$v.form.confirm_password.required">
              Esse campo é obrigatório
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.form.confirm_password.sameAsPassword"
            >
              Senhas não coincidem
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button type="submit" variant="primary" block :disabled="isLoading">
            {{ $t("general.change") }}
          </b-button>
        </section>
        <b-button
          variant="link"
          block
          @click="$emit('changeMode', 'login')"
          v-if="isLoading || !canChangePass"
        >
          {{ $t("auth.back-login") }}
        </b-button>
        <span v-if="!canChangePass" class="text-center d-block">
          Validando...
        </span>
        <progress-line v-if="isLoading || !canChangePass" class="loading" />
      </b-form>
    </div>
  </div>
</template>
<script>
import ProgressLine from "@/components/Progress";
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProgressLine,
  },
  mixins: [validationMixin],
  data() {
    return {
      isLoading: false,
      canChangePass: false,
      user_id: null,
      form: {
        password: null,
        confirm_password: null,
      },
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  computed: {
    ...mapGetters(["user", "token"]),
  },
  methods: {
    ...mapActions(["edit_user", "show_toast", "validate_key", "auth_success"]),
    handleSubmitNewPassword() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError && !this.$v.$anyError) {
        this.isLoading = true;
        let password = this.form.password;
        let user_id = this.user_id;
        this.edit_user({ id: user_id, password }).then(
          ({ data: { id, has_pending_inbound_process: hasPending } }) => {
            localStorage.setItem("token", this.token);
            localStorage.setItem("uuid", id);
            this.isLoading = false;
            if (id) {
              this.$router.push(hasPending ? "/inbound-process/" : "/");
              this.show_toast({
                message: "Login realizado com sucesso",
                type: "success",
              });
            } else {
              this.show_toast({
                message: "Falha ao logar com as credenciais informadas",
                type: "error",
              });
            }
          }
        );
      }
    },
    validateKey() {
      this.validate_key(this.key).then(({ token, user_id, user }) => {
        if (token) {
          this.user_id = user_id;
          this.canChangePass = true;
          this.isLoading = false;
          this.$store.commit("auth_success", { token, user });
        }
      });
    },
  },
  mounted() {
    this.key = this.$route.query.key;
    if (this.key) {
      this.validateKey();
    }
  },
};
</script>
