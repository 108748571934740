<template>
  <div>
    <span class="text-muted title mb-4" v-if="!emailSent">
      Digite seu e-mail para recuperar a senha
    </span>
    <div class="login-form" no-body>
      <section class="text-center">
        <span class="text-mutted" v-if="emailSent">
          Um link de alteração de senha foi enviado para
          <strong>{{ form.email }}</strong>
          <img
            src="../../../assets/images/email.svg"
            height="70"
            class="text-center my-4 d-block mx-auto"
          />
        </span>
      </section>
      <b-form @submit.prevent="handleValidateEmail()" v-if="!emailSent">
        <b-form-group :label="$t('general.email')">
          <b-form-input
            :disabled="isLoading"
            type="email"
            placeholder="email@email.com"
            v-model="$v.form.email.$model"
            :class="$v.form.email.$error && 'is-invalid'"
            trim
          >
          </b-form-input>
          <b-form-invalid-feedback v-if="!$v.form.email.required">
            {{ $t("auth.type-valid-email") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button block type="submit" variant="primary" :disabled="isLoading">
          <b-spinner v-if="isLoading" small class="mr-2" />
          <span> {{ $t("general.send") }}</span>
        </b-button>
      </b-form>
      <b-button variant="link" block @click="$emit('changeMode', 'login')">
        {{ $t("auth.back-login") }}
      </b-button>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  mixins: [validationMixin],
  data() {
    return {
      isLoading: false,
      emailSent: false,
      form: {
        email: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email: email,
      },
    },
  },
  methods: {
    ...mapActions(["recover", "show_toast"]),
    handleValidateEmail() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError && !this.$v.$anyError) {
        this.isLoading = true;
        let email = this.form.email;
        this.recover({ email }).then(({ status }) => {
          this.isLoading = false;
          if (status === 201) {
            this.emailSent = true;
            this.show_toast({
              message: "Email enviado com sucesso",
              type: "success",
            });
          } else {
            this.emailSent = false;
          }
        });
      }
    },
  },
};
</script>
