<template>
  <div>
    <span class="text-muted title mb-4">{{ $t("auth.login") }}</span>
    <b-form @submit.prevent="handleSubmitCredentials()">
      <div class="login-form" no-body>
        <b-form-group :label="$t('general.email')">
          <b-form-input
            type="email"
            :disabled="isLoading"
            placeholder="email@email.com"
            v-model="$v.form.email.$model"
            :class="$v.form.email.$error && 'is-invalid'"
            trim
          >
          </b-form-input>
          <b-form-invalid-feedback
            v-if="!$v.form.email.email || !$v.form.email.required"
          >
            {{ $t("auth.type-valid-email") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('auth.password')">
          <b-form-input
            :disabled="isLoading"
            type="password"
            placeholder="****"
            v-model="$v.form.password.$model"
            :class="$v.form.password.$error && 'is-invalid'"
            trim
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" block :disabled="isLoading">
          {{ $t("general.enter") }}
        </b-button>
        <b-button variant="link" block @click="$emit('changeMode', 'recover')">
          {{ $t("auth.recover") }}
        </b-button>
        <progress-line v-if="isLoading" class="loading" />
      </div>
    </b-form>
  </div>
</template>
<script>
import ProgressLine from "@/components/Progress";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProgressLine,
  },
  mixins: [validationMixin],
  data() {
    return {
      isLoading: false,
      form: {
        email: null,
        password: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["login", "show_toast"]),
    handleSubmitCredentials() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.isLoading = true;
        let email = this.form.email;
        let password = this.form.password;
        this.login({ email, password })
          .then(({ data: { token, user } }) => {
            this.isLoading = false;
            if (token) {
              this.show_toast({
                message: "Login realizado com sucesso",
                type: "success",
              });
              if (user.has_pending_inbound_process) {
                this.$router.push("/inbound-process/");
              } else {
                this.$router.push("/main/dashboard");
              }
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>