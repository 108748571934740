<template>
  <div class="content-login container-fluid">
    <div class="left">
      <div class="d-flex">
        <Login />
        <section>
          <h3 class="text-white">Contrate com segurança</h3>
          <p class="text-white">
            Diminua riscos na relação entre tomadores de serviços e
            terceirizadas
          </p>
        </section>
      </div>
    </div>
    <div class="right">
      <div class="form">
        <div class="head">
          <figure>
            <Logo alt="" height="60" />
          </figure>
        </div>
        <div>
          <form-login
            v-if="mode === 'login'"
            @changeMode="changeMode"
            class="animated"
            transition="bounce"
          />
          <form-recover v-if="mode === 'recover'" @changeMode="changeMode" />
          <form-change-password
            v-if="mode === 'change'"
            @changeMode="changeMode"
          />
        </div>
      </div>
      <div class="preamble">
        Maat © {{ date }} | {{ $t("general.all-right-reserved") }}
      </div>
    </div>
  </div>
</template>
 
<script>
import FormLogin from "./shared/FormLogin";
import FormChangePassword from "./shared/FormChangePassword";
import FormRecover from "./shared/FormRecover";
import Logo from "@/components/MaatLogo";
import Login from "@/components/Images/Login";
import { StatusBar, Style } from "@capacitor/status-bar";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      mode: "login",
      date: new Date().getFullYear(),
    };
  },
  components: {
    FormLogin,
    FormChangePassword,
    FormRecover,
    Logo,
    Login,
  },
  methods: {
    changeMode(mode) {
      this.$router.push(`/auth/${mode}`);
      this.mode = this.$route.params.type;
      this.$forceUpdate();
    },
  },
  computed: {
    ...mapGetters(["is_native_platform", "is_dark"]),
  },
  async mounted() {
    this.mode = this.$route.params.type;
    if (this.is_native_platform) {
      await StatusBar.setStyle({ style: Style.Dark });
      if (this.is_dark) {
        await StatusBar.setStyle({ style: Style.Dark });
        return await StatusBar.setBackgroundColor({ color: "#1e1e1e" });
      }
      await StatusBar.setStyle({ style: Style.Light });
      return await StatusBar.setBackgroundColor({ color: "#ffffff" });
    }
  },
};
</script>

<style lang="scss">
.content-login {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  padding: 0;
  .left,
  .right {
    width: 50%;
    height: 100%;
    @media (max-width: $break-md) {
      width: 100%;
    }
  }
  .right {
    position: relative;
    background: $bg-1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    display: block;
    background: $primary;
    display: flex;
    align-items: center;
    @media (max-width: $break-md) {
      display: none;
    }
    img {
      width: 100%;
      max-width: 400px;
    }
    div {
      display: flex;
      align-items: center;
      margin: auto;
      section {
        color: $white;
        padding: 0 2em;
        h3 {
          font-weight: bold;
        }
      }
    }
    @media (max-width: $break-md) {
      background: $bg-1;
    }
  }
  .title {
    text-align: center;
    display: block;
  }
  .login-form {
    padding: 30px 20px;
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: $break-md) {
      max-width: 100%;
      padding: 1em;
      margin: 0 auto;
    }
    .loading {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }
  .head {
    text-align: center;
  }
  .preamble {
    position: absolute;
    bottom: 2em;
    font-size: 14px;
    color: $text-color;
    font-weight: lighter;
    left: 0;
    right: 0;
    text-align: center;
  }
  .form {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
