<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="842px"
    height="290px"
    viewBox="0 0 842 290"
    preserveAspectRatio="xMidYMid meet"
    id="logo"
  >
    <g>
      <path
        d="M122.2 229.4 c-3.5 -2.4 -4.5 -5.9 -2.7 -9.3 1.5 -3.1 3.3 -4.1 7.1 -4.1 6.1 0 8.8 9 3.8 12.9 -3.2 2.5 -5.2 2.6 -8.2 0.5z"
      />
      <path
        d="M146.6 228.9 c-5 -3.9 -2.3 -12.9 3.8 -12.9 3.8 0 5.6 1 7.1 4.1 1.8 3.4 0.8 6.9 -2.7 9.3 -3 2.1 -5 2 -8.2 -0.5z"
      />
      <path
        d="M97 222 c-4.2 -4.2 -1.1 -11.3 5 -11.3 2.2 0 3.9 0.7 5.2 2.2 2.3 3 2.3 5.2 -0.3 8.5 -2.5 3.2 -7 3.5 -9.9 0.6z"
      />
      <path
        d="M171 222 c-3 -3 -2.7 -8.3 0.6 -10.9 3.2 -2.5 5.2 -2.6 8.2 -0.5 3.5 2.4 4.5 5.9 2.7 9.3 -2.3 4.6 -8 5.6 -11.5 2.1z"
      />
      <path
        d="M779.9 217 c-21.9 -2 -35.3 -13.1 -39.5 -32.9 -0.9 -4.6 -1.4 -14 -1.4 -31.3 l0 -24.8 -12.1 0 c-11 0 -12 -0.2 -11.5 -1.7 0.4 -1.6 3.7 -14.2 6.6 -25 l1.1 -4.3 7.9 0 8 0 0 -22.5 0 -22.5 18 0 18 0 0 22.5 0 22.5 12.1 0 c11 0 12 0.2 11.5 1.8 -0.3 0.9 -1.7 6.4 -3.1 12.2 -1.4 5.8 -3.1 12 -3.6 13.8 l-1.1 3.2 -7.9 0 -8 0 0.3 22.9 c0.3 22.9 0.3 22.9 2.8 25.6 4.4 4.7 7.8 5.9 17.1 5.9 l8.5 0 -4.9 17.1 c-2.8 9.3 -5 17 -5.1 17.1 -0.7 0.7 -7.7 0.9 -13.7 0.4z"
      />
      <path
        d="M492.4 215.6 c-24.3 -5.6 -44.3 -24 -49.9 -46.1 -2 -7.7 -1.9 -22.1 0.1 -29.9 7 -27.2 34.3 -47 64.9 -46.8 29.3 0.1 53.9 16.7 63.8 42.8 2.1 5.6 2.2 7.5 2.5 42.2 l0.3 36.2 -18 0 c-15.4 0 -18.1 -0.2 -18.1 -1.5 0 -1.9 0.4 -2 -8.7 1.1 -9.6 3.3 -27.1 4.2 -36.9 2z m28.2 -34.1 c6.5 -2.9 12 -8.4 15.2 -15.3 3.4 -7.4 2.9 -18.6 -1.1 -25.4 -5.9 -10.2 -15 -15.3 -27.2 -15.3 -6.7 0 -8.9 0.4 -13.7 2.8 -6.5 3.2 -12.2 9 -15 15.4 -2.6 5.8 -2.2 17.8 0.7 23.8 7.2 14.7 25.6 21 41.1 14z"
      />
      <path
        d="M632.4 215.5 c-24 -5.2 -44.3 -23.9 -49.9 -46 -2 -7.7 -1.9 -22.1 0.1 -29.9 7 -27.2 34.3 -47 64.9 -46.8 29.3 0.1 53.9 16.7 63.8 42.8 2.1 5.6 2.2 7.5 2.5 42.2 l0.3 36.2 -18 0 c-17.3 0 -18.1 -0.1 -18.1 -1.9 0 -2 -0.1 -2 -4.5 0 -10.6 4.7 -28.4 6.2 -41.1 3.4z m28.2 -34 c6.5 -2.9 12 -8.4 15.2 -15.3 3.4 -7.4 2.9 -18.6 -1.1 -25.4 -5.9 -10.2 -15 -15.3 -27.2 -15.3 -6.7 0 -8.9 0.4 -13.7 2.8 -6.5 3.2 -12.2 9 -15 15.4 -2.6 5.8 -2.2 17.8 0.7 23.8 7.2 14.7 25.6 21 41.1 14z"
      />
      <path
        d="M284 132.3 c0 -77.6 0.1 -81.5 1.8 -80.1 32.1 27.5 71.3 60.8 71.7 60.8 0.2 0 17 -13.6 37.2 -30.2 20.3 -16.6 37.4 -30.6 38.1 -31.1 0.9 -0.8 1.2 15.5 1.2 80.7 l0 81.6 -21 0 -21 0 -0.2 -38.5 -0.3 -38.6 -16.9 16.1 -16.8 16.2 -2.7 -2.3 c-1.4 -1.3 -8.5 -8.1 -15.7 -15.2 l-13.1 -12.9 -0.6 8.4 c-0.4 4.6 -0.7 21.5 -0.7 37.6 l0 29.2 -20.5 0 -20.5 0 0 -81.7z"
      />
      <path
        d="M75.1 209.4 c-3.3 -4.2 -2.6 -7.6 2 -9.9 6.8 -3.6 13.3 5.1 7.9 10.5 -2.9 2.9 -7.4 2.6 -9.9 -0.6z"
      />
      <path
        d="M194 209.8 c-2.5 -2.7 -2.6 -5.9 -0.2 -8.9 3.9 -4.7 12.2 -1.6 12.2 4.7 0 5.7 -8 8.5 -12 4.2z"
      />
      <path
        d="M143 210.2 c-4.9 -1.5 -6.8 -8.3 -3.2 -11.5 2.5 -2.3 7.5 -2.1 9.7 0.3 4.3 4.7 -0.5 13.1 -6.5 11.2z"
      />
      <path
        d="M119.2 206.4 c-4.3 -3 -1.8 -10.4 3.6 -10.4 2.7 0 6.2 4.4 5.5 7 -0.6 2.1 -3.9 5 -5.8 5 -0.6 0 -2.1 -0.7 -3.3 -1.6z"
      />
      <path
        d="M161.9 203.9 c-2.8 -2.8 -2.2 -7.6 1.3 -10.2 2.3 -1.7 3.1 -1.8 5.6 -0.8 3.3 1.4 5.5 5.2 4.5 7.8 -1.9 5.4 -7.6 7 -11.4 3.2z"
      />
      <path
        d="M97.5 199.5 c-3.1 -3 -3.1 -3.7 -0.1 -7.2 2.1 -2.4 2.7 -2.6 5.2 -1.8 1.8 0.6 3.3 2.1 3.9 3.9 0.8 2.5 0.6 3.1 -1.8 5.2 -3.5 3 -4.2 3 -7.2 -0.1z"
      />
      <path
        d="M184.2 196.3 c-0.7 -0.3 -1.9 -1.8 -2.7 -3.3 -3.6 -6.9 5.1 -13.4 10.5 -8 5.3 5.3 -0.7 14.2 -7.8 11.3z"
      />
      <path
        d="M58.2 193.4 c-4.2 -2.9 -1.8 -10.4 3.5 -10.4 4.9 0 7.2 7.6 3.1 10.4 -1.2 0.9 -2.7 1.6 -3.3 1.6 -0.6 0 -2.1 -0.7 -3.3 -1.6z"
      />
      <path
        d="M214.1 192.4 c-1.2 -1.5 -2.1 -3 -2.1 -3.4 0 -1.6 4.3 -6 5.9 -6 2.8 0 6.1 3 6.1 5.6 0 2.9 -3.2 6.4 -5.9 6.4 -1.1 0 -2.9 -1.2 -4 -2.6z"
      />
      <path
        d="M80.2 186.8 c-1.5 -1.5 -1.5 -5.1 0 -6.6 2.8 -2.8 7.8 -0.7 7.8 3.3 0 2.7 -1.8 4.5 -4.5 4.5 -1.2 0 -2.6 -0.5 -3.3 -1.2z"
      />
      <path
        d="M201 180 c-3.9 -3.9 -1.4 -10 4.2 -10 4.2 0 7.3 5.2 5 8.6 -0.8 1.3 -4.6 3.4 -6.1 3.4 -0.6 0 -2 -0.9 -3.1 -2z"
      />
      <path
        d="M130.6 174 c-8.4 -1.5 -8.2 -3.1 0.7 -6.4 3 -1.2 4.7 -2.3 4.3 -3.1 -0.2 -0.7 0.1 -1.8 0.7 -2.4 1.5 -1.5 2.1 -23.9 0.7 -25.6 -0.7 -0.9 -0.7 -1.5 -0.1 -1.9 0.6 -0.4 1 -9.6 1.1 -23.1 0 -17.6 -0.2 -22.5 -1.2 -22.5 -0.8 -0.1 -0.6 -0.6 0.5 -1.4 1.2 -0.9 1.4 -1.6 0.8 -2.1 -0.6 -0.3 -1.1 -1.9 -1.1 -3.4 0 -5.8 -9.3 -8.6 -19 -5.7 -2.9 0.9 -5.8 1.6 -6.4 1.6 -1.4 0 5.7 -3.3 10.7 -4.9 4.7 -1.6 12.7 -0.7 12.7 1.5 0 0.8 0.9 1.4 2.1 1.4 2 0 2.1 0.4 1.6 4.5 -0.6 4.1 -0.5 4.5 1.4 4.5 1.9 0 2.1 -0.5 1.7 -4.5 -0.3 -4.1 -0.1 -4.5 1.9 -4.5 1.3 0 2.3 -0.6 2.3 -1.4 0 -3.2 10.1 -3.1 16.2 0 1.5 0.8 5.1 2.1 8 2.8 2.9 0.8 4.4 1.5 3.3 1.5 -1.1 0.1 -5.2 -0.8 -9 -1.9 -3.8 -1.1 -8.5 -2 -10.4 -2 -3.7 0 -11.3 3.8 -10.8 5.4 0.2 0.6 0 2.2 -0.5 3.5 -0.5 1.5 -0.4 2.8 0.3 3.7 0.9 1.1 0.9 1.4 0 1.4 -1.7 0 -1.4 44.7 0.2 45.3 0.9 0.3 0.9 0.8 0.1 1.8 -1.6 2 -1.9 12.9 -0.4 12.9 0.9 0 0.9 0.4 0.2 1.3 -1.3 1.5 -0.7 10.6 0.7 12 0.5 0.5 0.7 1.5 0.5 2.2 -0.3 0.7 1.8 2 5.6 3.4 3.3 1.2 6 2.6 6 3.2 0 2.7 -16.2 4.6 -25.4 2.9z"
      />
      <path
        d="M46 171 c-2.5 -2.5 -2.5 -3.2 -0.2 -6 1.4 -1.8 2.5 -2.1 4.5 -1.6 3.5 0.8 4.6 4.9 2.2 7.6 -2.3 2.5 -4 2.5 -6.5 0z"
      />
      <path
        d="M230.5 171 c-2.7 -3 -0.7 -7 3.4 -7 4.5 0 6.6 4.4 3.5 7.4 -2.1 2.2 -4.7 2 -6.9 -0.4z"
      />
      <path
        d="M65.1 169.2 c-1.3 -2.5 0.5 -5.2 3.4 -5.2 1.5 0 2.6 0.7 3 2 1.3 4.1 -4.4 7 -6.4 3.2z"
      />
      <path
        d="M216.2 161.4 c-4 -2.7 -1.8 -10.4 2.9 -10.4 2.9 0 5.9 3.1 5.9 6 0 2.8 -3 6 -5.5 6 -0.6 0 -2.1 -0.7 -3.3 -1.6z"
      />
      <path
        d="M39 147.5 c-2.6 -3.1 1.3 -8.2 4.4 -5.6 1.9 1.6 2.1 4.2 0.4 5.9 -1.6 1.6 -3.3 1.5 -4.8 -0.3z"
      />
      <path
        d="M56.2 147.4 c0.4 -2.1 2.2 -2.3 2.6 -0.1 0.2 1 -0.3 1.7 -1.3 1.7 -1 0 -1.5 -0.6 -1.3 -1.6z"
      />
      <path
        d="M242 147.5 c-2.6 -3.1 1.3 -8.2 4.4 -5.6 1.9 1.6 2.1 4.2 0.4 5.9 -1.6 1.6 -3.3 1.5 -4.8 -0.3z"
      />
      <path
        d="M225.8 140.8 c-2.7 -2.3 -2.8 -4.6 -0.3 -7.5 2.3 -2.7 2.5 -2.7 5.2 -1.3 3.4 1.9 4.1 5.4 1.7 8.1 -2 2.2 -4.4 2.4 -6.6 0.7z"
      />
      <path
        d="M84.9 134.9 c-1.9 -1.9 -2.3 -4.1 -0.6 -4 0.4 0.1 7.9 0.1 16.7 0.1 8.8 0 16 0.4 16 0.8 0 0.5 -0.9 1.8 -2 3 -1.9 2 -3 2.2 -15 2.2 -11.8 0 -13.2 -0.2 -15.1 -2.1z"
      />
      <path
        d="M165.6 134.9 c-1.4 -1.1 -2.6 -2.5 -2.6 -3 0 -0.5 3.8 -0.9 8.5 -0.9 l8.5 0 0 -27.5 c-0.1 -16.6 -0.5 -27.5 -1.1 -27.5 -1.5 0 -1 -3 0.7 -3.7 2.2 -0.8 4 1.2 2.6 2.9 -0.7 0.9 -1.1 10.3 -1.2 28.5 l0 27.2 8.7 0.3 8.8 0.3 -2.7 2.8 c-2.7 2.7 -2.9 2.7 -15.1 2.7 -11.1 0 -12.7 -0.2 -15.1 -2.1z"
      />
      <path
        d="M39 119.5 c0 -1 0.7 -1.5 1.8 -1.3 2.1 0.4 1.9 2.2 -0.2 2.6 -1 0.2 -1.6 -0.3 -1.6 -1.3z"
      />
      <path
        d="M248 120 c0 -0.5 0.7 -1 1.5 -1 0.8 0 1.5 0.5 1.5 1 0 0.6 -0.7 1 -1.5 1 -0.8 0 -1.5 -0.4 -1.5 -1z"
      />
      <path
        d="M231.1 115.1 c-1.3 -2.4 0.8 -5.5 3.4 -5 2.8 0.6 4.2 3.4 2.6 5.3 -1.8 2.2 -4.8 2 -6 -0.3z"
      />
      <path
        d="M231.2 91.7 c-1.5 -1.7 0.1 -4.2 2.3 -3.3 1.8 0.6 1.7 3.7 -0.1 4.3 -0.6 0.2 -1.6 -0.2 -2.2 -1z"
      />
      <path
        d="M98.7 76.4 c-1.6 -1.6 -0.7 -4.4 1.4 -4.4 1.5 0 2 0.5 1.7 2.2 -0.3 2.2 -1.9 3.3 -3.1 2.2z"
      />
      <path
        d="M139.7 72.3 c-1.3 -1.3 -0.7 -5.3 0.8 -5.3 0.8 0 1.5 0.8 1.5 1.8 0 2.2 -1.4 4.4 -2.3 3.5z"
      />
      <path
        d="M117.5 67.5 c-3.3 -1.2 -3.5 -1.3 -1 -1 1.7 0.2 4.8 0 7 -0.4 l4 -0.9 -4.6 -0.1 c-4.9 -0.1 -14.5 -2.6 -15.3 -4 -0.4 -0.5 1 -0.5 3.2 0 2 0.5 5.7 0.8 8.2 0.8 l4.5 -0.1 -5.4 -0.9 c-5.7 -1 -12.3 -3.6 -15.6 -6.3 -1.8 -1.5 -1.7 -1.5 1.8 -0.1 4.3 1.8 13.3 3.7 12 2.6 -0.4 -0.4 -2.8 -1.3 -5.2 -2 -2.5 -0.7 -6 -2.9 -8.5 -5.2 l-4.1 -4 4 2.5 c2.2 1.3 6.5 3.2 9.5 4 6 1.8 5.7 0.6 -0.3 -1.4 -4.2 -1.3 -10.1 -6 -11.9 -9.4 -0.9 -1.7 -0.6 -1.6 1.8 0.6 3.4 3.2 12.3 7.4 17.7 8.3 3.6 0.7 7.7 4.4 7.7 7 0 0.7 1.3 3 3 5.3 1.6 2.3 2.5 4.4 2 4.7 -2.2 1.4 -10.7 1.4 -14.5 0z"
      />
      <path
        d="M136.1 68.3 c-0.1 -0.6 0.9 -3.3 2 -6 l2.1 -4.8 1.9 4.4 c1.1 2.4 1.7 4.6 1.4 4.9 -0.3 0.3 -0.5 0 -0.5 -0.6 0 -2.2 -5 -1.3 -6 1.1 -0.5 1.2 -0.9 1.7 -0.9 1z"
      />
      <path
        d="M150.8 68.3 c-3.3 -0.5 -3.4 -1.2 -0.8 -4.6 1.1 -1.4 2.9 -4.5 3.9 -7.1 1.9 -4.2 2.4 -4.6 7.8 -6.1 8.8 -2.5 13 -4.5 16.9 -8.3 l3.6 -3.5 -1.6 3.1 c-1.9 3.8 -6.9 7.7 -12.2 9.6 -2.6 1 -3.2 1.5 -1.8 1.5 1.2 0.1 4.8 -1.2 8 -2.9 3.2 -1.6 6 -2.8 6.2 -2.6 0.9 1 -7.7 6.6 -12.5 8.1 -3 0.9 -5.3 1.9 -5.1 2.1 0.6 0.5 11.1 -2.2 13.8 -3.6 2.5 -1.3 2.5 -1.3 0.6 0.8 -2.3 2.6 -8.6 5.1 -15.7 6.3 -5.3 0.8 -5.2 0.8 1.6 0.4 3.9 -0.2 7.9 -0.7 8.9 -1.1 1.3 -0.5 1.7 -0.4 1.2 0.4 -1 1.6 -10.2 4.2 -15.5 4.3 l-4.6 0.1 3.5 0.9 c1.9 0.5 4.9 0.6 6.5 0.3 2.5 -0.4 2.7 -0.3 1.3 0.6 -2.1 1.3 -10 2 -14 1.3z"
      />
      <path
        d="M227.2 68.1 c0.2 -0.7 0.7 -1.2 1.1 -1.2 1.2 0.1 0.9 1.5 -0.3 2 -0.7 0.2 -1.1 -0.2 -0.8 -0.8z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Logo",
};
</script>
<style lang="scss" scoped>
#logo {
  g {
    fill: $primary;
  }
}
</style>